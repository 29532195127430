import { defineStore } from "pinia";

export const useSession = defineStore("session", () => {
    const user = ref(null)

    function setUser(data) {
        user.value = data
    }

    return {
        user,
        setUser
    }
})
