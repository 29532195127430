import { useAuth } from 'vue-clerk'
import { useSession } from '~/stores/session'

export default defineNuxtRouteMiddleware(async (to, from) => {
  // isSignedIn here can be used in both SSR and CSR
  // since we set an initial state in the plugin!
  const { isSignedIn, userId } = useAuth()

  if(!from.name && isSignedIn.value) {
    const sessionStore = useSession()

    const { data: user } = await useAsyncData('getuser', () => $fetch("/api/user/" + userId.value))

    if(!user.value) {
        const { data: newUser } = await useAsyncData('createuser', () => $fetch("/api/user/create", { method: 'POST' , body: { id: userId.value }}))
        sessionStore.setUser(newUser.value)
    } else {
        sessionStore.setUser(user.value)
    }
  }
})
