import { default as _91id_93RUWi3puMEcMeta } from "/vercel/path0/pages/appointments/[id].vue?macro=true";
import { default as indexxsECH1DW5BMeta } from "/vercel/path0/pages/appointments/index.vue?macro=true";
import { default as newVEYRArKZOZMeta } from "/vercel/path0/pages/appointments/new.vue?macro=true";
import { default as _91appointmentId_93jRMMIU8mhQMeta } from "/vercel/path0/pages/clients/[id]/appointments/[appointmentId].vue?macro=true";
import { default as new9aLtswxdGLMeta } from "/vercel/path0/pages/clients/[id]/appointments/new.vue?macro=true";
import { default as editbOYW36281NMeta } from "/vercel/path0/pages/clients/[id]/edit.vue?macro=true";
import { default as indexwfLIwM8mYCMeta } from "/vercel/path0/pages/clients/[id]/index.vue?macro=true";
import { default as index4BLhNDCiYNMeta } from "/vercel/path0/pages/clients/index.vue?macro=true";
import { default as newpo6erZihnIMeta } from "/vercel/path0/pages/clients/new.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_930iw7OeLn15Meta } from "/vercel/path0/pages/services/[id].vue?macro=true";
import { default as indexkd184pULVkMeta } from "/vercel/path0/pages/services/index.vue?macro=true";
import { default as newQ6gfXPaxlKMeta } from "/vercel/path0/pages/services/new.vue?macro=true";
import { default as sign_45innR2QzqahJfMeta } from "/vercel/path0/pages/sign-in.vue?macro=true";
import { default as sign_45upHNIilXjClyMeta } from "/vercel/path0/pages/sign-up.vue?macro=true";
import { default as indexd6cjWr4zZcMeta } from "/vercel/path0/pages/stats/index.vue?macro=true";
export default [
  {
    name: "appointments-id",
    path: "/appointments/:id()",
    meta: _91id_93RUWi3puMEcMeta || {},
    component: () => import("/vercel/path0/pages/appointments/[id].vue")
  },
  {
    name: "appointments",
    path: "/appointments",
    meta: indexxsECH1DW5BMeta || {},
    component: () => import("/vercel/path0/pages/appointments/index.vue")
  },
  {
    name: "appointments-new",
    path: "/appointments/new",
    meta: newVEYRArKZOZMeta || {},
    component: () => import("/vercel/path0/pages/appointments/new.vue")
  },
  {
    name: "clients-id-appointments-appointmentId",
    path: "/clients/:id()/appointments/:appointmentId()",
    meta: _91appointmentId_93jRMMIU8mhQMeta || {},
    component: () => import("/vercel/path0/pages/clients/[id]/appointments/[appointmentId].vue")
  },
  {
    name: "clients-id-appointments-new",
    path: "/clients/:id()/appointments/new",
    meta: new9aLtswxdGLMeta || {},
    component: () => import("/vercel/path0/pages/clients/[id]/appointments/new.vue")
  },
  {
    name: "clients-id-edit",
    path: "/clients/:id()/edit",
    meta: editbOYW36281NMeta || {},
    component: () => import("/vercel/path0/pages/clients/[id]/edit.vue")
  },
  {
    name: "clients-id",
    path: "/clients/:id()",
    meta: indexwfLIwM8mYCMeta || {},
    component: () => import("/vercel/path0/pages/clients/[id]/index.vue")
  },
  {
    name: "clients",
    path: "/clients",
    meta: index4BLhNDCiYNMeta || {},
    component: () => import("/vercel/path0/pages/clients/index.vue")
  },
  {
    name: "clients-new",
    path: "/clients/new",
    meta: newpo6erZihnIMeta || {},
    component: () => import("/vercel/path0/pages/clients/new.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "services-id",
    path: "/services/:id()",
    meta: _91id_930iw7OeLn15Meta || {},
    component: () => import("/vercel/path0/pages/services/[id].vue")
  },
  {
    name: "services",
    path: "/services",
    meta: indexkd184pULVkMeta || {},
    component: () => import("/vercel/path0/pages/services/index.vue")
  },
  {
    name: "services-new",
    path: "/services/new",
    meta: newQ6gfXPaxlKMeta || {},
    component: () => import("/vercel/path0/pages/services/new.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45innR2QzqahJfMeta || {},
    component: () => import("/vercel/path0/pages/sign-in.vue")
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upHNIilXjClyMeta || {},
    component: () => import("/vercel/path0/pages/sign-up.vue")
  },
  {
    name: "stats",
    path: "/stats",
    meta: indexd6cjWr4zZcMeta || {},
    component: () => import("/vercel/path0/pages/stats/index.vue")
  }
]