import validate from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/validate.js";
import user_45global from "/vercel/path0/middleware/user.global.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  user_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/vercel/path0/node_modules/vue-clerk/dist/nuxt/runtime/middleware/auth.js"),
  guest: () => import("/vercel/path0/node_modules/vue-clerk/dist/nuxt/runtime/middleware/guest.js")
}